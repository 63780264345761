<template>
  <div>
    msg
  </div>
</template>
<script>
export default {
  name: "App",
  data: () => ({
    msg: "Msg",
  }),
  methods: {},
};
</script>
<style scoped></style>
